/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import RightColumns from '../../../components/Columns/RightColumns';
import MainForm from './EditLink.tsx/MainForm';
import useSidebar from '../../../stores/links/useSidebar';
import LinkDetails from './AddLink/LinkDetails';
import useOwners from '../../../customHooks/useOwners';
import useLinkStatus from '../../../customHooks/useLinkStatus';
import useLinkForm from '../../../stores/links/useLinkForm';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { GlobalContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import useSites from '../../../customHooks/useSites';
import ContactsAdd from './AddLink/ContactsAdd';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { MessagesInt, SeverityType } from '../../../models/General';
import { createLink, sendErrorSubmit } from '../../../helpers/links';
import { ContactsFormDefault, LinkFormDefault } from '../../../models/LinkForm';
import AddWarningLink from '../AddWarningLink';
import AddFormHeader from './AddLink/AddFormHeader';

const FormBox = () => {
  // GLOBAL VARIABLES WITH CONTEXT
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;

  // GLOBAL VARIABLES ZUSTAND
  const {
    formSidebar,
    loadingForm,
    updateLoadingForm,
    updateSidebar,
    updateAvailable,
  } = useSidebar();
  const { linkForm, replaceLinkForm } = useLinkForm();

  // CUSTOM HOOKS FOR THE ENDPOINTS
  const { owners, loading: loadingOwners } = useOwners({ withFields: true });
  const { statusOptions, loadingStatusOptions } = useLinkStatus();
  const { sites: sitesOptions, loading: loadingSites } = useSites({
    userId: UserContext.id,
    minimal: true,
  });

  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = (value: boolean) => setOpenWarning(value);

  const [activeStep, setActiveStep] = useState(0);

  const [stepOneFormValid, setStepOneFormValid] = useState(false);
  const [stepTwoFormValid, setStepTwoFormValid] = useState(true);

  const [openMessage, setOpenMessage] = useState(false);
  const [messagesProps, setMessagesProps] = useState<MessagesInt>({
    message: '',
    severity: 'error',
  });

  const handleFeedbackMessage = (message: string, severity?: SeverityType) => {
    setOpenMessage(true);
    setMessagesProps({ message, severity: severity ?? 'error' });
  };

  const saveLink = async () => {
    updateLoadingForm(true);
    try {
      await createLink(linkForm);

      handleFeedbackMessage(`Link created successfully`, 'success');
      // fetchLinks();
      replaceLinkForm(LinkFormDefault);
      updateSidebar({ type: 'load' });
      updateAvailable({ disabled: true, disabledSaveEmail: true });
    } catch (err) {
      const error = err as any;
      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(linkForm),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error creating link`, 'error');
    } finally {
      updateLoadingForm(false);
    }
  };

  useEffect(() => {
    if (formSidebar.type === 'add' && activeStep !== 0) {
      setActiveStep(0);
      setStepOneFormValid(false);
      setStepTwoFormValid(true);
    }
  }, [formSidebar]);

  const validateClear = () => {
    const { contacts } = linkForm;

    if (contacts.length > 1 || linkForm.url !== '') return true;

    if (contacts.length === 1) {
      const singleContact = contacts[0];

      const isEqual =
        JSON.stringify(ContactsFormDefault) === JSON.stringify(singleContact);

      return !isEqual;
    }

    return false;
  };

  const clearForm = () => {
    const clearedForm = { ...LinkFormDefault };
    clearedForm.owner = UserContext.id;
    clearedForm.assignee = UserContext.id;
    clearedForm.site = GlobalContext.site_selected ?? 0;

    replaceLinkForm(clearedForm);

    setActiveStep(0);
    closeWarning(false);
  };

  return (
    <Box
      position="relative"
      height="100vh"
      overflow={loadingForm ? 'hidden' : 'auto'}
    >
      <RightColumns>
        <Typography
          fontWeight={700}
          fontSize={15}
          marginBottom={1.5}
          textTransform="capitalize"
          textAlign="center"
        >
          {formSidebar.type === 'add' && 'Create Link'}
          {(formSidebar.type === 'edit' ||
            formSidebar.type === 'read' ||
            formSidebar.type === 'load') &&
            'Link Details'}
        </Typography>

        {/* EDIT LINKS */}
        {(formSidebar.type === 'edit' ||
          formSidebar.type === 'read' ||
          formSidebar.type === 'load') && (
          <MainForm
            handleFeedbackMessage={handleFeedbackMessage}
            ownersField={{ owners, loadingOwners }}
            sitesField={{ sitesOptions, loadingSites }}
            statusField={{ statusOptions, loadingStatusOptions }}
          />
        )}

        {/* ADD LINKS */}

        {formSidebar.type === 'add' && (
          <>
            <AddFormHeader
              activeStep={activeStep}
              saveLink={saveLink}
              updateStep={(step: number) => setActiveStep(step)}
              loadingForm={loadingForm}
              stepOneFormValid={stepOneFormValid}
              stepTwoFormValid={stepTwoFormValid}
              updateOpenWarning={(value: boolean) => setOpenWarning(value)}
              validateClear={!validateClear()}
            />

            <Divider />
            <Box
              sx={{
                visibility: activeStep === 0 ? 'visible' : 'hidden',
                height: activeStep === 0 ? 'auto' : 0,
              }}
            >
              <LinkDetails
                ownersField={{ owners, loadingOwners }}
                statusField={{ statusOptions, loadingStatusOptions }}
                sitesField={{ sitesOptions, loadingSites }}
                onFormValidityChange={setStepOneFormValid}
              />
            </Box>
            <Box
              sx={{
                visibility: activeStep === 1 ? 'visible' : 'hidden',
                height: activeStep === 1 ? 'auto' : 0,
              }}
            >
              <ContactsAdd
                handleFeedbackMessage={handleFeedbackMessage}
                onContactsValidityChange={setStepTwoFormValid}
                stepTwoFormValid={stepTwoFormValid}
              />
            </Box>
          </>
        )}
      </RightColumns>
      {loadingForm && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            position: 'absolute',
            top: 65,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          open={loadingForm}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <AddWarningLink
        open={openWarning}
        closeModal={closeWarning}
        body="If you decide to continue you will lose your progress on this link"
        continueModal={() => {
          clearForm();
        }}
      />
    </Box>
  );
};

export default FormBox;

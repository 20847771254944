import { create } from 'zustand';
import { LinkFormDefault, ObjEditFormLink } from '../../models/LinkForm';
import {
  ContactsFormLink,
  EditObjectLink,
  ObjectLinks,
} from '../../models/Forms';
import {
  ContactsValuesType,
  contactValueDefault,
  validateResponseEmailContact,
} from '../../helpers/contacts';
import { isURL } from '../../helpers/validateInputs';
import { validateEmail } from '../../helpers/user';
import { PendingList, ValidateEmailInt } from '../../models/Links';
import { IS_USER_ADMIN_MANAGER } from '../../constants/user';

interface ContactError {
  name?: string;
  email?: string;
  status?: string;
  form_url?: string;
  form_text?: string;
  validation_status?: string;
}

interface LinkFormErrors {
  url?: string;
  site?: string;
  status?: string;
  owner?: string;
  contacts: ContactError[];
}

interface LinkFormStore {
  linkForm: ObjectLinks;
  validUrl: boolean;
  tabForm: number;
  updateTabForm: (value: number) => void;
  updateValidUrl: (value: boolean) => void;
  linkFormErrors: LinkFormErrors;
  setLinkFormErrors: (errors: Partial<LinkFormErrors>) => void;
  updateLinkForm: (obj: Partial<ObjectLinks>, site?: boolean) => void;
  replaceLinkForm: (obj: ObjectLinks) => void;
  replaceObjEditForm: (
    objEdit: EditObjectLink,
    obj: ObjectLinks,
    isAdmin: boolean,
  ) => void;
  editLinkForm: EditObjectLink;
  loadingForm: boolean;
  updateLoadingForm: (value: boolean) => void;
  contactsValues: ContactsValuesType[];
  addContactValues: (obj: ContactsValuesType) => void;
  validateField: (index: number, field: keyof ContactsFormLink) => void;
  validateLinkFormField: (field: keyof ObjectLinks) => void;
  setLoadingEmail: (index: number, isLoading: boolean) => void;
  addContact: () => void;
  toggleIsForm: (index: number, isForm: boolean) => void;
  deleteContact: (index: number, isAdmin: boolean) => void;
  updateContact: (index: number, contact: Partial<ContactsFormLink>) => void;
  replaceContacts: (contacts: ContactsFormLink[]) => void;
  isFormValid: () => boolean;
  getOriginalStatus: (contactId: number) => string | undefined;
  validateEmail: (index: number, isAdmin: boolean) => Promise<void>;
  isDuplicateEmail: (email: string, currentIndex: number) => boolean;
  updateContactMessage: (index: number, message: string) => void;
  processValidationResponse: (resp: ValidateEmailInt) => ContactsValuesType;
  replaceContactInForm: (
    index: number,
    contactNewValue: ContactsValuesType,
    isAdmin: boolean,
  ) => void;
  pendingListObject?: PendingList;
  updatePendingListObject: (obj: PendingList) => void;
  resetPendingListObject: () => void;
  updateContactValues: (
    index: number,
    contactNewValue: ContactsValuesType,
    isAdmin: boolean,
  ) => void;
}

const useLinkForm = create<LinkFormStore>((set, get) => ({
  linkForm: LinkFormDefault,
  validUrl: true,
  tabForm: 0,
  updateTabForm: value => set({ tabForm: value }),

  linkFormErrors: {
    contacts: [{}],
  },
  contactsValues: [
    {
      loading: false,
      validEmail: true,
      message: '',
      previousChanged: false,
      contact: null,
    },
  ],
  updateValidUrl: value => set({ validUrl: value }),
  updatePendingListObject: value => set({ pendingListObject: value }),
  resetPendingListObject: () => set({ pendingListObject: undefined }),

  setLoadingEmail: (index, isLoading) =>
    set(state => {
      const updatedContactsValues = [...state.contactsValues];
      updatedContactsValues[index].loading = isLoading;
      return { contactsValues: updatedContactsValues };
    }),

  setLinkFormErrors: errors =>
    set(state => ({
      linkFormErrors: { ...state.linkFormErrors, ...errors },
    })),

  addContactValues: obj =>
    set(state => ({
      contactsValues: [...state.contactsValues, obj],
    })),

  editLinkForm: ObjEditFormLink,

  loadingForm: false,

  updateLinkForm: async (obj, site) => {
    set(state => ({
      linkForm: { ...state.linkForm, ...obj },
    }));
    if (site) {
      const validationPromises = get().linkForm.contacts.map((contact, i) => {
        if (!contact.is_form && contact.email) {
          return get().validateEmail(i, false);
        }
        return Promise.resolve();
      });

      await Promise.all(validationPromises);
    }
  },

  replaceLinkForm: obj => set({ linkForm: obj }),

  replaceObjEditForm: async (objEdit, obj, isAdmin) => {
    const newErrors = obj.contacts.map(() => ({}));

    const newContactsValues = obj.contacts.map(contact => ({
      loading: false,
      validEmail: true,
      message: '',
      previousChanged: false,
      contact: null,
    }));

    set({
      linkFormErrors: { contacts: newErrors },
      contactsValues: newContactsValues,
      linkForm: { ...obj },
      editLinkForm: objEdit,
    });

    const validationPromises = obj.contacts.map((contact, i) => {
      if (!contact.is_form && contact.email) {
        return get().validateEmail(i, isAdmin);
      }
      return Promise.resolve();
    });

    await Promise.all(validationPromises);

    set({ validUrl: true });
  },

  updateLoadingForm: value => set({ loadingForm: value }),

  validateField: (index, field) => {
    const { linkForm, linkFormErrors } = get();
    const contact = linkForm.contacts[index];
    const errors = [...linkFormErrors.contacts];

    if (field === 'email' && !contact.email && !contact.is_form) {
      errors[index] = { ...errors[index], email: 'Email is required' };
    } else if (field === 'status' && !contact.status) {
      errors[index] = { ...errors[index], status: 'Status is required' };
    } else if (contact.is_form) {
      if (field === 'name' && !contact.name)
        errors[index] = { ...errors[index], name: 'Name is required' };
      else if (field === 'form_text' && !contact.form_text)
        errors[index] = {
          ...errors[index],
          form_text: 'Form text is required',
        };
      else if (field === 'form_url') {
        if (!contact.form_url)
          errors[index] = { ...errors[index], form_url: 'URL is required' };
        else if (!/^https?:\/\/\S+\.\S+$/.test(contact.form_url))
          errors[index] = {
            ...errors[index],
            form_url: 'Please enter a valid URL',
          };
        else errors[index].form_url = undefined;
      }
    } else {
      errors[index] = { ...errors[index], [field]: undefined };
    }

    set(state => ({
      linkFormErrors: { ...state.linkFormErrors, contacts: errors },
    }));
  },

  validateLinkFormField: field => {
    const { linkForm, linkFormErrors } = get();
    const errors = { ...linkFormErrors };
    let newValidUrl = false;

    if (field === 'url') {
      if (!linkForm.url) {
        errors.url = 'URL is required';
        newValidUrl = false;
      } else if (!isURL(linkForm.url)) {
        errors.url = 'Invalid URL format';
        newValidUrl = false;
      } else {
        errors.url = undefined;
        newValidUrl = true;
      }
    }

    if (field === 'site' && !linkForm.site)
      errors.site = 'Site selection is required';
    else if (field === 'site') errors.site = undefined;

    if (field === 'status' && !linkForm.status)
      errors.status = 'Status selection is required';
    else if (field === 'status') errors.status = undefined;

    if (field === 'owner' && !linkForm.owner)
      errors.owner = 'Owner selection si required';
    else if (field === 'owner') errors.owner = undefined;

    set(state => ({
      linkFormErrors: errors,
      validUrl: field === 'url' ? newValidUrl : state.validUrl,
    }));
  },

  isFormValid: () => {
    const { linkForm, contactsValues, linkFormErrors, validUrl } = get();

    const linkFormFieldsValid = !(
      linkFormErrors.site ||
      linkFormErrors.status ||
      linkFormErrors.owner
    );

    const contactsValid = linkForm.contacts.every((contact, index) => {
      const isForm = contact.is_form;
      return (
        (isForm
          ? contact.name && contact.form_url && contact.form_text
          : contact.email && contactsValues[index].validEmail === true) &&
        contact.status
      );
    });

    return linkFormFieldsValid && contactsValid && validUrl;
  },

  addContact: () =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: [
          ...state.linkForm.contacts,
          {
            name: '',
            email: '',
            is_form: false,
            status: 'open',
            is_default: false,
            validation_status: 'active',
          },
        ],
      },
      linkFormErrors: {
        ...state.linkFormErrors,
        contacts: [...state.linkFormErrors.contacts, {}],
      },
      contactsValues: [
        ...state.contactsValues,
        {
          loading: false,
          validEmail: false,
          message: '',
          previousChanged: false,
          contact: null,
        },
      ],
    })),

  toggleIsForm: (index, isForm) =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: state.linkForm.contacts.map((contact, i) =>
          i === index
            ? {
                ...contact,
                is_form: isForm,
                name: '',
                email: '',
                form_text: '',
                form_url: '',
                is_default: false,
              }
            : contact,
        ),
      },
      linkFormErrors: {
        ...state.linkFormErrors,
        contacts: state.linkFormErrors.contacts.map((error, i) =>
          i === index ? {} : error,
        ),
      },
      contactsValues: state.contactsValues.map((value, i) =>
        i === index
          ? {
              loading: false,
              validEmail: false,
              message: '',
              previousChanged: false,
              contact: null,
            }
          : value,
      ),
    })),

  deleteContact: async (index, isAdmin) => {
    const { linkForm } = get();
    const updatedContacts = linkForm.contacts.filter((_, i) => i !== index);

    const updatedErrors = get().linkFormErrors.contacts.filter(
      (_, i) => i !== index,
    );
    const updatedContactsValues = get().contactsValues.filter(
      (_, i) => i !== index,
    );

    set({
      linkForm: { ...linkForm, contacts: updatedContacts },
      linkFormErrors: { ...get().linkFormErrors, contacts: updatedErrors },
      contactsValues: updatedContactsValues,
    });

    const validationPromises = updatedContacts.map((contact, i) => {
      if (!contact.is_form && contact.email) {
        return get().validateEmail(i, isAdmin);
      }
      return Promise.resolve();
    });

    await Promise.all(validationPromises);
  },

  updateContact: (index, contact) =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: state.linkForm.contacts.map((c, i) =>
          i === index ? { ...c, ...contact } : c,
        ),
      },
    })),

  replaceContacts: contacts =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts,
      },
    })),

  getOriginalStatus: contactId => {
    const { editLinkForm } = get();

    if (!editLinkForm || !editLinkForm.contacts || contactId === 0)
      return undefined;

    const originalContact = editLinkForm.contacts.find(
      contact => contact.id === contactId,
    );

    return originalContact
      ? originalContact.validation_status.toLowerCase()
      : undefined;
  },

  // VALIDATION OF EMAIL

  validateEmail: async (index, isAdmin) => {
    const {
      linkForm,
      setLoadingEmail,
      replaceContactInForm,
      updateContactMessage,
      isDuplicateEmail,
      processValidationResponse,
      updateContactValues,
    } = get();

    const { email, id } = linkForm.contacts[index];

    if (!email) return;

    setLoadingEmail(index, true);
    updateContactMessage(index, '');

    if (isDuplicateEmail(email, index)) {
      updateContactMessage(index, 'This email already exists');
      setLoadingEmail(index, false);
      return;
    }

    try {
      const response = await validateEmail(email, linkForm.site, id);

      const contactNewValue = processValidationResponse(response);

      replaceContactInForm(index, contactNewValue, isAdmin);

      updateContactValues(index, contactNewValue, isAdmin);
    } catch (err) {
      updateContactMessage(index, 'Error validating email');
    } finally {
      setLoadingEmail(index, false);
    }
  },

  updateContactMessage: (index, message) => {
    const updatedContactsValues = [...get().contactsValues];
    updatedContactsValues[index] = { ...updatedContactsValues[index], message };
    set({ contactsValues: updatedContactsValues });
  },

  processValidationResponse: resp => {
    if (!resp.valid_contact) {
      const contactValue: ContactsValuesType = {
        loading: false,
        validEmail: true,
        message: '',
        previousChanged: false,
        contact: null,
      };
      contactValue.message = resp.message;
      contactValue.validEmail = false;
      if (resp.contact !== null && resp.duplicate_contact) {
        contactValue.contact = resp.contact;
        if (contactValue.contact.last_outreach === '')
          delete contactValue.contact.last_outreach;
        contactValue.previousChanged = true;
      } else contactValue.previousChanged = false;

      return contactValue;
    }
    return validateResponseEmailContact(resp);
  },

  isDuplicateEmail: (email, currentIndex) => {
    const { linkForm } = get();
    return linkForm.contacts.some(
      (contact, idx) => contact.email === email && idx !== currentIndex,
    );
  },

  replaceContactInForm: (index, contactNewValue, isAdmin) => {
    const { linkForm, getOriginalStatus } = get();
    const updatedContacts = [...linkForm.contacts];
    if (contactNewValue.contact === null) {
      updatedContacts[index] = {
        ...updatedContacts[index],
        email: linkForm.contacts[index].email,
        form_text: '',
        form_url: '',
        name: '',
        status: 'open',
        is_default: isAdmin ? true : contactNewValue.validEmail === true,
      };
      delete updatedContacts[index].id;
      delete updatedContacts[index].last_outreach;
    } else if (contactNewValue.contact !== null) {
      updatedContacts[index] = { ...contactNewValue.contact };
      const isValidated = contactNewValue.validEmail === true;
      if (
        updatedContacts.filter(item => item.is_default).length === 0 ||
        updatedContacts[index].is_default
      ) {
        updatedContacts[index].is_default = isAdmin ? true : isValidated;
      }

      const statusDefault = getOriginalStatus(contactNewValue.contact.id ?? 0);

      updatedContacts[index].validation_status =
        contactNewValue.contact.validation_status ?? statusDefault;

      if (!contactNewValue.contact.id) delete updatedContacts[index].id;
    }

    set({ linkForm: { ...linkForm, contacts: updatedContacts } });
  },

  updateContactValues: (index, contactNewValue, isAdmin) => {
    const { contactsValues } = get();
    const updatedContactsValues = [...contactsValues];
    updatedContactsValues[index] = {
      ...contactNewValue,
      validEmail: isAdmin ? true : contactNewValue.validEmail,
    };
    set({ contactsValues: updatedContactsValues });
  },
}));

export default useLinkForm;

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import {
  filtersObj,
  filtersObjWithDate,
  VisibleColumnsInterface,
} from '../../../models/Filters';
import DateFilters from '../../../components/Filters/DateFilters';
import {
  GlobalContextType,
  LoadingContextType,
  RequestContextType,
} from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import SitesAccordion from '../../../components/Filters/SitesAccordion';
import AutoCompleteSelect from '../../../components/Filters/AutoCompleteSelect';
import { fetchTemplatesFilter } from '../../../helpers/templates';
import RequestContext from '../../../context/RequestContext';
import LoadingContext from '../../../context/LoadingContext';
import FavFilters from '../../../components/Filters/FavFilters/FavFilters';
import { BasicArray, MessagesInt } from '../../../models/General';
import FeedbackMessage from '../../../components/FeedbackMessage';
import {
  ListFavoriteFilter,
  Owners,
  UserContextType,
} from '../../../models/User';
import { ProjectsRequest } from '../../../models/Projects';
import { DefaultCheckbox, SearchTextField } from '../../../components/inputs';
import { fetchSitesAudiencesAffinitiesMinimal } from '../../../helpers/sites';
import useFiltersLinks from '../../../stores/filters/useFiltersLinks';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { IS_USER_ADMIN } from '../../../constants/user';
import Context from '../../../context/UserContext';
import { MainButton } from '../../../components/buttons';

interface Props {
  columnsVisibility: VisibleColumnsInterface;
  setColumnsVisible: React.Dispatch<
    React.SetStateAction<VisibleColumnsInterface>
  >;
  owners: Owners[];
  projects: ProjectsRequest[];
  status: BasicArray[];
  tab: { numberTab: number; loadingTab: boolean };
  favFiltersList: ListFavoriteFilter[];
  updateFormDrawer: () => void;
}

const Filters = ({
  columnsVisibility,
  setColumnsVisible,
  owners,
  projects,
  status,
  tab,
  favFiltersList,
  updateFormDrawer,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const [favFilterId, setFavFilterId] = useState(0);
  const filtersLinks = useFiltersLinks();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState<MessagesInt>({
    message: '',
    severity: 'success',
  });
  const { RequestsContext, setRequestsContext } = useContext(
    RequestContext,
  ) as RequestContextType;
  const [clearAll, setClearAll] = useState(false);
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;

  const [templates, setTemplates] = useState<BasicArray[]>([]);
  const [affinities, setAffinities] = useState<BasicArray[]>([]);
  const [audience, setAudience] = useState<BasicArray[]>([]);
  const [render, setRender] = useState(0);
  const [disabledAddFav, setDisabledAddFav] = useState(true);

  // useEffect(() => {
  //   if (render !== 0 && !isLoadingContext.loading && tab.numberTab === 0) {
  //     let queryFinal = '';
  //     if (searchFiltersLinks.search_url !== '') {
  //       queryFinal += searchFiltersLinks.exact_url
  //         ? `&search_url_exact=${searchFiltersLinks.search_url}`
  //         : `&search_url=${searchFiltersLinks.search_url}`;
  //     }
  //     if (searchFiltersLinks.search_email !== '') {
  //       queryFinal += searchFiltersLinks.exact_email
  //         ? `&search_email_exact=${searchFiltersLinks.search_email}`
  //         : `&search_email=${searchFiltersLinks.search_email}`;
  //     }
  //     setQueryFinalSearch(queryFinal);
  //   }
  // }, [searchFiltersLinks]);

  const getQueryFinal = (search_url: string, search_email: string) => {
    let queryFinal = '';
    if (search_url !== '') {
      queryFinal += `&search_url=${search_url}`;
    }
    if (search_email !== '') {
      queryFinal += `&search_email=${search_email}`;
    }
    return queryFinal;
  };

  const createQuery = () => {
    const copyObj = { ...filtersLinks.filterValuesDefault };
    const queryFinal = getQueryFinal(
      copyObj.search_url ?? '',
      copyObj.search_email ?? '',
    );
    delete copyObj.search_email;
    delete copyObj.search_url;
    const copyFilters = JSON.parse(
      JSON.stringify(filtersLinks.filterValuesDefault),
    );
    copyFilters.sites_ids.value =
      copyFilters.sites_ids.value !== ''
        ? copyFilters.sites_ids.value.replaceAll(':', ',')
        : '';
    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    if (arrayElements.length > 0) setDisabledAddFav(false);
    else setDisabledAddFav(true);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );
    setGlobalContext({
      ...GlobalContext,
      unclaimed: { ...GlobalContext.unclaimed, order: '-created' },
      claims: { ...GlobalContext.claims, order: 'modified' },
      master: { ...GlobalContext.master, order: 'link' },
      links: {
        ...GlobalContext.links,
        page: 1,
        page_size: 50,
        query_filters: queryString.join(''),
        search_filter: queryFinal,
      },
    });
  };

  useEffect(() => {
    if (filtersLinks.baseFilter) createQuery();
  }, [filtersLinks.filterValuesDefault]);

  useEffect(() => {
    if (filtersLinks.baseFilter) {
      filtersLinks.updateFiltersChange(filtersLinks.baseFilter);
      filtersLinks.updateFiltersDefault(filtersLinks.baseFilter);
    }
  }, [filtersLinks.baseFilter]);

  // useEffect(() => {
  //   if (filtersLinks.baseFilter) {
  //     filtersLinks.updateFiltersChange(filtersLinks.baseFilter);
  //     filtersLinks.updateFiltersDefault(filtersLinks.baseFilter);
  //   }
  // }, []);

  const updateFavFilterId = (id: number) => setFavFilterId(id);

  const showMessage = (obj: MessagesInt) => {
    setFeedback(obj);
    setFeedbackOpen(true);
  };

  const updateFiltersValue = (id: string, value: string) => {
    let obj = { ...filtersLinks.filterValuesChange };
    if (id === 'sites_ids') {
      if (value !== '') {
        obj = {
          ...obj,
          sites_ids: { id, value },
          templates_ids: { ...obj.templates_ids, value: '' },
        };
        filtersLinks.updateFiltersChange(obj);
      }

      if (value === '') {
        obj = {
          ...obj,
          sites_ids: { id, value },
          templates_ids: { ...obj.templates_ids, value: '' },
          audiences_ids: { ...obj.audiences_ids, value: '' },
          affinities_ids: { ...obj.affinities_ids, value: '' },
        };
        filtersLinks.updateFiltersChange(obj);
      }
      return;
    }
    obj = { ...obj, [id]: { id, value } };
    filtersLinks.updateFiltersChange(obj);
  };

  const updateDates = (id: string, value: string | null) => {
    let obj = { ...filtersLinks.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filtersLinks.updateFiltersChange(obj);
  };

  const onEnterOption = () =>
    filtersLinks.updateFiltersDefault(filtersLinks.filterValuesChange);

  const filtersArray = [
    // {
    //   id: 13,
    //   visibilityId: 'email_contacts',
    //   visible: undefined,
    //   countUse:
    //     filtersLinks.filterValuesChange.show_only_emails.value !== '' ? 1 : 0,
    //   title: 'Email Contacts',
    //   body: (
    //     <>
    //       <FlexBox>
    //         <DefaultCheckbox
    //           size="small"
    //           onClick={() => {
    //             let obj = { ...filtersLinks.filterValuesChange };
    //             obj = {
    //               ...obj,
    //               show_only_emails: {
    //                 id: 'show_only_emails',
    //                 value:
    //                   filtersLinks.filterValuesChange.show_only_emails.value ===
    //                   ''
    //                     ? 'true'
    //                     : '',
    //               },
    //             };
    //             filtersLinks.updateFiltersChange(obj);
    //           }}
    //           checked={
    //             filtersLinks.filterValuesChange.show_only_emails.value !== ''
    //           }
    //         />
    //         <Typography fontSize={12}>
    //           Only entries with email contacts
    //         </Typography>
    //       </FlexBox>
    //       {IS_USER_ADMIN.includes(UserContext.profile.role) && (
    //         <FlexBox>
    //           <DefaultCheckbox
    //             size="small"
    //             onClick={() => {
    //               let obj = { ...filtersLinks.filterValuesChange };
    //               obj = {
    //                 ...obj,
    //                 notify_opens: {
    //                   id: 'notify_opens',
    //                   value:
    //                     filtersLinks.filterValuesChange.notify_opens.value ===
    //                     ''
    //                       ? 'true'
    //                       : '',
    //                 },
    //               };
    //               filtersLinks.updateFiltersChange(obj);
    //             }}
    //             checked={
    //               filtersLinks.filterValuesChange.notify_opens.value !== ''
    //             }
    //           />
    //           <Typography fontSize={12}>
    //             Only entries with opened emails
    //           </Typography>
    //         </FlexBox>
    //       )}
    //     </>
    //   ),
    //   disabled: false,
    // },

    {
      id: 12,
      visibilityId: 'cannibalization_vis',
      visible: undefined,
      countUse:
        filtersLinks.filterValuesChange.show_cannibalization_attempts.value !==
        ''
          ? 1
          : 0,
      title: 'Cannibalization Attempts',
      body: (
        <FlexBox>
          <DefaultCheckbox
            size="small"
            onClick={() => {
              let obj = { ...filtersLinks.filterValuesChange };
              obj = {
                ...obj,
                show_cannibalization_attempts: {
                  id: 'show_cannibalization_attempts',
                  value:
                    filtersLinks.filterValuesChange
                      .show_cannibalization_attempts.value === ''
                      ? 'true'
                      : '',
                },
              };
              filtersLinks.updateFiltersChange(obj);
            }}
            checked={
              filtersLinks.filterValuesChange.show_cannibalization_attempts
                .value !== ''
            }
          />
          <Typography fontSize={12}>Show Cannibalization Attempts</Typography>
        </FlexBox>
      ),
      disabled: false,
    },
    {
      id: 16,
      visibilityId: 'is_unsubscribed',
      visible: columnsVisibility.is_unsubscribed,
      countUse:
        filtersLinks.filterValuesChange.is_unsubscribed?.value !== '' ? 1 : 0,
      title: 'Unsubscribed',
      body: (
        <FlexBox>
          <DefaultCheckbox
            size="small"
            onClick={() => {
              let obj = { ...filtersLinks.filterValuesChange };
              obj = {
                ...obj,
                is_unsubscribed: {
                  id: 'is_unsubscribed',
                  value:
                    filtersLinks.filterValuesChange.is_unsubscribed?.value ===
                    ''
                      ? 'true'
                      : '',
                },
              };
              filtersLinks.updateFiltersChange(obj);
            }}
            checked={
              filtersLinks.filterValuesChange.is_unsubscribed?.value !== ''
            }
          />
          <Typography fontSize={12}>Is Unsubscribed</Typography>
        </FlexBox>
      ),
      disabled: false,
    },
    {
      id: 2,
      visibilityId: 'site__name',
      title: 'Site',
      countUse:
        filtersLinks.filterValuesChange.sites_ids.value !== ''
          ? filtersLinks.filterValuesChange.sites_ids.value
              .split(',')
              .map(item => item.split(':'))
              .map(e => e.map(Number))
              .flat().length
          : 0,
      visible: columnsVisibility.site__name,
      body: (
        <SitesAccordion
          favFilters={favFiltersList.length}
          projectsArr={projects}
          value={filtersLinks.filterValuesChange.sites_ids.value}
          updateFiltersValue={updateFiltersValue}
          clearAll={clearAll}
        />
      ),
      disabled: !(projects.length > 0),
    },

    {
      id: 4,
      visible: columnsVisibility.audience__name,
      countUse:
        filtersLinks.filterValuesChange.audiences_ids.value !== ''
          ? filtersLinks.filterValuesChange.audiences_ids.value.split(',')
              .length
          : 0,
      visibilityId: 'audience__name',
      title: 'Audience',
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          options={audience}
          placeholder="Audience"
          updateFiltersValue={updateFiltersValue}
          onEnter={onEnterOption}
          clearAll={clearAll}
          value={audience.filter(item => {
            const id = item.id as number;
            return (
              filtersLinks.filterValuesChange.audiences_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          id={filtersLinks.filterValuesChange.audiences_ids.id}
        />
      ),
      disabled: !(audience.length > 0),
    },
    {
      id: 3,
      title: 'Affinity',
      countUse:
        filtersLinks.filterValuesChange.affinities_ids.value !== ''
          ? filtersLinks.filterValuesChange.affinities_ids.value.split(',')
              .length
          : 0,
      visibilityId: 'affinity__name',
      visible: columnsVisibility.affinity__name,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          clearAll={clearAll}
          options={affinities}
          onEnter={onEnterOption}
          placeholder="Affinity"
          value={affinities.filter(item => {
            const id = item.id as number;
            return (
              filtersLinks.filterValuesChange.affinities_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          updateFiltersValue={updateFiltersValue}
          id={filtersLinks.filterValuesChange.affinities_ids.id}
        />
      ),
      disabled: !(affinities.length > 0),
    },
    {
      id: 6,
      visibilityId: 'template__title',
      visible: columnsVisibility.template__title,
      countUse:
        filtersLinks.filterValuesChange.templates_ids.value !== ''
          ? filtersLinks.filterValuesChange.templates_ids.value.split(',')
              .length
          : 0,
      title: 'Template',
      body: (
        <AutoCompleteSelect
          clearAll={clearAll}
          options={templates}
          onEnter={onEnterOption}
          placeholder="Template"
          value={templates.filter(item => {
            const id = item.id as number;
            return (
              filtersLinks.filterValuesChange.templates_ids.value
                .split(',')
                .indexOf(id.toString()) !== -1
            );
          })}
          id={filtersLinks.filterValuesChange.templates_ids.id}
          disabledInput={isLoadingContext.loading}
          updateFiltersValue={updateFiltersValue}
        />
      ),
      disabled: !(templates.length > 0),
    },
    {
      id: 5,
      visibilityId: 'owner__username',
      title: 'Owner',
      countUse:
        filtersLinks.filterValuesChange.owner.value !== ''
          ? filtersLinks.filterValuesChange.owner.value.split(',').length
          : 0,
      visible: columnsVisibility.owner__username,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          options={owners.map(item => {
            return { id: item.id, name: item.username };
          })}
          clearAll={clearAll}
          onEnter={onEnterOption}
          placeholder="Owner"
          updateFiltersValue={updateFiltersValue}
          value={owners
            .map(item => {
              return { id: item.id, name: item.username };
            })
            .filter(item => {
              const id = item.id as number;
              return (
                filtersLinks.filterValuesChange.owner.value
                  .split(',')
                  .indexOf(id.toString()) !== -1
              );
            })}
          id={filtersLinks.filterValuesChange.owner.id}
        />
      ),
      disabled: !(owners.length > 0),
    },
    {
      id: 7,
      visibilityId: 'created',
      title: 'Added Date',
      visible: columnsVisibility.created,
      countUse:
        filtersLinks.filterValuesChange.min_added_date.value &&
        filtersLinks.filterValuesChange.max_added_date.value
          ? 2
          : filtersLinks.filterValuesChange.max_added_date.value ||
            filtersLinks.filterValuesChange.min_added_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          onEnter={onEnterOption}
          disableInput={isLoadingContext.loading}
          min_id={filtersLinks.filterValuesChange.min_added_date.id}
          min_value={filtersLinks.filterValuesChange.min_added_date.value}
          max_id={filtersLinks.filterValuesChange.max_added_date.id}
          max_value={filtersLinks.filterValuesChange.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 8,
      visibilityId: 'modified',
      title: 'Updated Date',
      visible: columnsVisibility.modified,
      countUse:
        filtersLinks.filterValuesChange.min_mod_date.value &&
        filtersLinks.filterValuesChange.max_mod_date.value
          ? 2
          : filtersLinks.filterValuesChange.max_mod_date.value ||
            filtersLinks.filterValuesChange.min_mod_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          onEnter={onEnterOption}
          min_id={filtersLinks.filterValuesChange.min_mod_date.id}
          min_value={filtersLinks.filterValuesChange.min_mod_date.value}
          disableInput={isLoadingContext.loading}
          max_id={filtersLinks.filterValuesChange.max_mod_date.id}
          max_value={filtersLinks.filterValuesChange.max_mod_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 9,
      visibilityId: 'found_not_null',
      visible: columnsVisibility.found_not_null,
      title: 'Found Date',
      countUse:
        filtersLinks.filterValuesChange.min_found_date.value &&
        filtersLinks.filterValuesChange.max_found_date.value
          ? 2
          : filtersLinks.filterValuesChange.max_found_date.value ||
            filtersLinks.filterValuesChange.min_found_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          onEnter={onEnterOption}
          min_id={filtersLinks.filterValuesChange.min_found_date.id}
          disableInput={isLoadingContext.loading}
          min_value={filtersLinks.filterValuesChange.min_found_date.value}
          max_id={filtersLinks.filterValuesChange.max_found_date.id}
          max_value={filtersLinks.filterValuesChange.max_found_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 10,
      visibilityId: 'status',
      title: 'Status',
      visible: columnsVisibility.status,
      countUse:
        filtersLinks.filterValuesChange.status?.value !== ''
          ? filtersLinks.filterValuesChange.status?.value.split(',').length
          : 0,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading}
          options={status}
          placeholder="Status"
          updateFiltersValue={updateFiltersValue}
          onEnter={onEnterOption}
          clearAll={clearAll}
          value={status.filter(item => {
            const id = item.id as string;
            return (
              filtersLinks.filterValuesChange.status?.value
                .split(',')
                .indexOf(id) !== -1
            );
          })}
          id={filtersLinks.filterValuesChange.status?.id ?? '0'}
        />
      ),
      disabled: !(status.length > 0),
    },
  ];

  useEffect(() => {
    if (owners.length > 0 && RequestsContext.sites.length > 0)
      setRequestsContext({ ...RequestsContext, owners });
  }, [owners, RequestsContext.sites]);

  const getTemplateInfo = async () => {
    try {
      const resp = await fetchTemplatesFilter(
        filtersLinks.filterValuesChange.audiences_ids.value,
        filtersLinks.filterValuesChange.affinities_ids.value,
      );
      const templatesTransform = resp
        .filter(item => item.status === 'active')
        .map(temp => {
          return { id: temp.id, name: temp.title };
        });
      setTemplates(
        templatesTransform.sort((a, b) => (a.name < b.name ? -1 : 1)),
      );
    } catch (err) {
      setTemplates([]);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      if (
        filtersLinks.filterValuesChange.affinities_ids.value !== '' &&
        filtersLinks.filterValuesChange.audiences_ids.value !== ''
      ) {
        getTemplateInfo();
      }
    }
  }, [
    filtersLinks.filterValuesChange.affinities_ids,
    filtersLinks.filterValuesChange.audiences_ids,
  ]);

  const responseAudAff = async (site_ids: string) => {
    try {
      const resp = await fetchSitesAudiencesAffinitiesMinimal(site_ids);
      const aud = resp.map(item => item.audiences);
      const aff = resp.map(item => item.affinities);
      return { aud: aud.flat(), aff: aff.flat() };
    } catch (err) {
      return { aud: [], aff: [] };
    }
  };

  const getAudAff = async (site_ids: string) => {
    setTemplates([]);
    if (site_ids === '') return;
    const transformSiteIds = site_ids.replaceAll(':', ',');
    const resp = await responseAudAff(transformSiteIds);
    setAffinities(resp.aff);
    setAudience(resp.aud);
  };

  useEffect(() => {
    if (render !== 0) {
      if (filtersLinks.filterValuesChange.sites_ids.value !== '') {
        setAudience([]);
        setAffinities([]);
        getAudAff(filtersLinks.filterValuesChange.sites_ids.value);
      } else {
        setTemplates([]);
        setAudience([]);
        setAffinities([]);
      }
    }
  }, [filtersLinks.filterValuesChange.sites_ids]);

  useEffect(() => {
    setRender(1);
  }, []);

  return (
    <Box marginTop={2}>
      <FlexBoxBetween px={3}>
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <IconButton
          aria-label="close"
          disabled={false}
          id="long-button"
          aria-haspopup="true"
          onClick={() => updateFormDrawer()}
        >
          <CloseIcon
            sx={{
              color: 'black',
              '&:hover': { color: 'red' },
            }}
          />
        </IconButton>
      </FlexBoxBetween>
      <FlexBoxBetween px={3} mt={1}>
        <MainButton
          disabled={isLoadingContext.loading}
          size="small"
          variant="contained"
          type="submit"
          onClick={() => {
            filtersLinks.updateFiltersDefault(filtersLinks.filterValuesChange);
            updateFormDrawer();
          }}
        >
          Apply Filters
        </MainButton>
        <Typography
          onClick={() => {
            if (isLoadingContext.loading) return;
            filtersLinks.updateFiltersChange(filtersObj);
            filtersLinks.updateFiltersDefault(filtersObj);
            updateFormDrawer();
            setTimeout(() => {
              setClearAll(!clearAll);
            }, 700);
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </FlexBoxBetween>
      <Box marginTop={1.5}>
        <Divider sx={{ border: '1px solid rgba(0, 0, 0, 0.06)' }} />

        <FavFilters
          disabledAdd={disabledAddFav}
          setClearAll={setClearAll}
          clearAll={clearAll}
          showMessage={showMessage}
          updateFavFilterId={updateFavFilterId}
          favFilterId={favFilterId}
        />
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            visible={filter.visible}
            countUse={filter.countUse}
            body={filter.body}
            disabled={filter.disabled}
            visibilityId={filter.visibilityId}
            columnsVisibility={columnsVisibility}
            setColumnsVisible={setColumnsVisible}
          />
        ))}
      </Box>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="left"
        severity={feedback.severity}
        message={feedback.message}
      />
    </Box>
  );
};

export default Filters;

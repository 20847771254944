import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { SelectFormInput } from '../../../components/Forms/FormInputs';
import { BasicArray, SeverityType } from '../../../models/General';
import { FormButtons } from '../../../components/buttons';
import {
  SelectSnooze,
  SelectTime,
  TextInputField,
} from '../../../components/inputs';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import {
  fetchTemplatesFilter,
  getTemplateTranslate,
} from '../../../helpers/templates';
import CustomToolbar from './CustomToolBar';
import ScheduleSend from './ScheduleSend';
import { scheduleSendEmail } from '../../../helpers/links';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 2,
  pt: 2,
  pb: 3,
};

interface LinkEmailSaveProps {
  open: boolean;
  closeModal: () => void;
  site: number;
  contacts: string[];
  contactsIds: number[];
  url: string;
  linkId: number;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
}

type ArrayOptions = {
  name: string;
  id: number;
};

const LinkEmailSave: React.FC<LinkEmailSaveProps> = ({
  open,
  closeModal,
  site,
  contacts,
  url,
  linkId,
  contactsIds,
  handleFeedbackMessage,
}) => {
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);

  const quillRef = useRef<ReactQuill | null>(null);

  const [loadingSend, setLoadingSend] = useState(false);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<ArrayOptions[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<ArrayOptions[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ArrayOptions[]>([]);

  const { UserContext } = useContext(Context) as UserContextType;
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [contactsList, setContactsList] = useState<string[]>([]);
  const [audienceSelected, setAudienceSelected] = useState(0);
  const [affinitySelected, setAffinitySelected] = useState(0);
  const [templateSelected, setTemplateSelected] = useState(0);

  const [loadingBody, setLoadingBody] = useState(false);

  const [scheduleSendModal, setScheduleSendModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [snoozedUntil, setSnoozedUntil] = useState(7);

  const handleBodyChange = (content: string) => setBody(content);

  const handleDeleteContact = (email: string) => {
    const updatedContacts = contactsList.filter(contact => contact !== email);

    setContactsList(updatedContacts);
  };

  useEffect(() => {
    if (contacts.length > 0) setContactsList(contacts);
    else setContactsList([]);

    return () => {
      setContactsList([]);
    };
  }, [contacts]);

  const transformContent = (content: string) => {
    return content.replace(/\n/g, '<br>');
  };

  const getBodyTemplate = async () => {
    setLoadingBody(true);
    try {
      const resp = await getTemplateTranslate(
        templateSelected,
        encodeURIComponent(url),
        linkId,
        encodeURIComponent(contactsList.join(',')),
      );

      setBody(transformContent(resp.body));
    } catch (err) {
      setBody('');
    } finally {
      setLoadingBody(false);
    }
  };

  useEffect(() => {
    if (templateSelected !== 0) getBodyTemplate();
  }, [templateSelected]);

  const getTemplates = async () => {
    setLoadingTemplates(true);
    setTemplateSelected(0);
    setTemplateOptions([]);
    cancelRequestTemplate();
    try {
      const resp = await fetchTemplatesFilter(
        audienceSelected,
        affinitySelected,
        site,
      );

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (affinitySelected !== 0) getTemplates();
  }, [affinitySelected]);

  const getAffinities = async () => {
    setLoadingAff(true);
    setAffinitySelected(0);
    setTemplateSelected(0);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAff();
    cancelRequestTemplate();

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audienceSelected,
        site,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  useEffect(() => {
    if (audienceSelected !== 0) getAffinities();
  }, [audienceSelected]);

  const getAudiences = async () => {
    setLoadingAud(true);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAud();
    cancelRequestAff();
    cancelRequestTemplate();
    if (site === 0) {
      setLoadingAud(false);
      return;
    }

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  useEffect(() => {
    getAudiences();

    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
      setAudienceSelected(0);
      setAffinitySelected(0);
      setTemplateSelected(0);
    };
  }, [site]);

  useEffect(() => {
    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
      setAudienceSelected(0);
      setAffinitySelected(0);
      setTemplateSelected(0);
    };
  }, []);

  const handleUndo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.undo();
    }
  };

  const handleRedo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.redo();
    }
  };

  const customHandlers = {
    undo: () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.history.undo();
      }
    },
    redo: () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.history.redo();
      }
    },
  };

  const transformTimeBlockTo24Hour = (timeBlock: string) => {
    const [startTime] = timeBlock.split(' - ');

    const parsedTime = dayjs(startTime, 'h:mm A');

    return parsedTime.format('HH:mm:ss');
  };

  const handleSend = async () => {
    setLoadingSend(true);
    const object: any = {
      link_id: linkId,
      contact_id: contactsIds[0],
      audience_id: audienceSelected,
      affinity_id: affinitySelected,
      template_id: templateSelected,
      site_id: site,
      is_sender_user: true,
      body,
      subject,
    };
    const dateTransformed = dayjs(selectedDate).format('YYYY-MM-DD');
    const hour =
      selectedTimeBlock !== ''
        ? transformTimeBlockTo24Hour(selectedTimeBlock)
        : '';
    if (selectedDate) object.scheduled_date = `${dateTransformed} ${hour}`;
    if (snoozedUntil) object.snoozed_days = snoozedUntil;

    try {
      await scheduleSendEmail(object);
      handleFeedbackMessage(
        `Email ${selectedDate ? 'scheduled succesfully' : 'sent succesfully'}`,
        'success',
      );
      closeModal();
      setScheduleSendModal(false);
      setBody('');
      setSubject('');
      setSelectedDate('');
      setSelectedTimeBlock('');
      setSnoozedUntil(7);
      setAudienceSelected(0);
      setAffinitySelected(0);
      setTemplateSelected(0);
    } catch (err) {
      handleFeedbackMessage('Error sending email', 'error');
    } finally {
      setLoadingSend(false);
    }
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <FlexBoxBetween>
          <Typography fontWeight={600} fontSize={17} textTransform="capitalize">
            Initial Outreach Attempt
          </Typography>
          <IconButton onClick={closeModal} sx={{ fontSize: 22, border: 1 }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </FlexBoxBetween>
        <Divider sx={{ mb: 2, mt: 1 }} />

        <FlexBox gap={1}>
          <Typography fontSize={15} fontWeight={600}>
            Opportunity:
          </Typography>
          <Link
            href={url}
            target="_blank"
            color="#373737"
            underline="hover"
            fontSize={14}
          >
            {url}
          </Link>
        </FlexBox>

        <Box my={2}>
          <FlexBox>
            <Typography fontSize={16} mr={1}>
              From:
            </Typography>
            <Chip label={UserContext.email} variant="outlined" />
          </FlexBox>
          <FlexBox mt={2} flexWrap="wrap" gap={1}>
            <Typography fontSize={16} mr={1}>
              To:
            </Typography>
            {contactsList.map((contact, index) => (
              <>
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={contact}
                  variant="outlined"
                  onDelete={
                    contactsList.length === 1
                      ? undefined
                      : () => handleDeleteContact(contact)
                  }
                />
              </>
            ))}
          </FlexBox>

          <FlexBox mt={2.5} pr={2}>
            <Typography fontSize={16} mr={1}>
              Subject
            </Typography>
            <TextInputField
              value={subject}
              name="subject"
              disabled={false}
              error={false}
              onChange={e => setSubject(e.target.value)}
              size="small"
              placeholder="Subject"
              id="url"
              helperText={false}
              fullWidth
            />
          </FlexBox>
          <Grid container spacing={2} width="100%" mt={1}>
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAud}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={audienceOptions}
                disabled={loadingAud || audienceOptions.length === 0}
                placeholder="Audience"
                value={
                  audienceOptions.find(
                    item => item.id === audienceSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAudienceSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAff}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={affinityOptions}
                disabled={loadingAff || affinityOptions.length === 0}
                placeholder="Affinity"
                value={
                  affinityOptions.find(
                    item => item.id === affinitySelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAffinitySelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingTemplates}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={templateOptions}
                disabled={templateOptions.length === 0 || loadingTemplates}
                placeholder="Template"
                value={
                  templateOptions.find(
                    item => item.id === templateSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setTemplateSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
            <ReactQuill
              theme="snow"
              style={{ minHeight: '200px' }}
              className="my-custom-editor"
              readOnly={loadingBody}
              ref={quillRef}
              value={body}
              onChange={handleBodyChange}
              placeholder="Compose your message..."
              modules={{
                toolbar: {
                  container: '#custom-toolbar',
                  // handlers: customHandlers,
                },

                history: {
                  delay: 1000, // Delay between stack updates (ms)
                  maxStack: 50, // Maximum undo stack size
                  userOnly: true, // Only user-initiated changes
                },
              }}
            />
          </Box>
          <FlexBox mt={2} gap={2}>
            <Typography>Snooze for </Typography>
            <Select
              value={snoozedUntil}
              name="bulk"
              id="bulk"
              disabled={false}
              displayEmpty
              onChange={e => setSnoozedUntil(Number(e.target.value))}
              sx={{ fontSize: 12 }}
              IconComponent={KeyboardArrowDownIcon}
              size="small"
              input={<SelectSnooze />}
            >
              <MenuItem value={1}>1 days</MenuItem>
              <MenuItem value={2}>2 days</MenuItem>
              <MenuItem value={3}>3 days</MenuItem>
              <MenuItem value={4}>4 days</MenuItem>
              <MenuItem value={5}>5 days</MenuItem>
              <MenuItem value={6}>6 days</MenuItem>
              <MenuItem value={7}>7 days (Default)</MenuItem>
            </Select>
          </FlexBox>
          <FlexBox mt={3} gap={2}>
            <FormButtons
              variant="contained"
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === ''
              }
              loading={loadingSend}
              sx={{
                backgroundColor: '#84D0F0',
                py: '5px',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#84D0F0',
                },
              }}
              onClick={() => setScheduleSendModal(true)}
            >
              Schedule Send
            </FormButtons>
            <FormButtons
              loading={loadingSend}
              variant="contained"
              sx={{
                background: '#BAF372',
                padding: '5px 20px',

                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === ''
              }
              onClick={() => {}}
            >
              Send
            </FormButtons>
          </FlexBox>
        </Box>
        <ScheduleSend
          open={scheduleSendModal}
          send={handleSend}
          loading={loadingSend}
          closeModal={(value: boolean) => setScheduleSendModal(value)}
          selectedDate={selectedDate}
          selectedTimeBlock={selectedTimeBlock}
          updateSelectedTime={(value: string) => setSelectedTimeBlock(value)}
          updateSelectedDate={(value: string) => setSelectedDate(value)}
        />
      </Box>
    </Modal>
  );
};

export default LinkEmailSave;

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BoxAddSettings from '../../../../components/Forms/BoxAddSettings';
import { FormInputsProps } from '../../../../constants/form';
import { TextInputField } from '../../../../components/inputs';
import {
  OwnersSelectLinkFormField,
  SelectFormInput,
  StatusSelectLinkFormField,
} from '../../../../components/Forms/FormInputs';
import { sendBackObject } from '../../../../helpers/helpersfunctions';
import Context from '../../../../context/UserContext';
import { UserContextType } from '../../../../models/User';
import { IS_USER_ADMIN, ROLES_LINKS } from '../../../../constants/user';

import {
  OwnersFieldInterface,
  SitesFieldInterface,
  StatusFieldInterface,
} from '../FormModel';
import useLinkForm from '../../../../stores/links/useLinkForm';
import { ContactsFormLink, ObjectLinks } from '../../../../models/Forms';
import { isURL } from '../../../../helpers/validateInputs';
import LoadingContext from '../../../../context/LoadingContext';
import { LoadingContextType } from '../../../../models/Context';
import {
  fetchLinkHistory,
  fetchLinkNotes,
  sendErrorSubmit,
  updateLink,
  validateLink,
} from '../../../../helpers/links';
import useSidebar from '../../../../stores/links/useSidebar';
import { BasicArray, SeverityType } from '../../../../models/General';
import { LinkFormDefault, ObjEditFormLink } from '../../../../models/LinkForm';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { FormButtons, MainButton } from '../../../../components/buttons';
import TabParent from '../../../../components/Tab/TabParent';
import { HistoryReq, NotesReq } from '../../../../models/Notes';
import LinkNotes from './LinkNotes';
import LinkHistory from './LinkHistory';
import StatusModal from '../../../../components/Forms/StatusModal';
import { LinkStatuses } from '../../../../models/Status';
import ContactsEdit from './ContactsEdit';
import AddNewContactModal from '../../../../components/Forms/Links/AddNewContactModal';
import StatusClosed from '../../../../components/Forms/Links/StatusClosed/StatusClosed';
import DateInput from '../../../../components/Forms/DateInput';
import LinkEmailSave from '../LinkEmailSave';
import AddWarningLink from '../../AddWarningLink';

interface MainFormProps {
  ownersField: OwnersFieldInterface;
  statusField: StatusFieldInterface;
  sitesField: SitesFieldInterface;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
}

const MainForm: React.FC<MainFormProps> = ({
  ownersField,
  statusField,
  sitesField,
  handleFeedbackMessage,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativetime);
  const { UserContext } = useContext(Context) as UserContextType;

  const [notes, setNotes] = useState<NotesReq[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const [history, setHistory] = useState<HistoryReq[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const [openNewContact, setOpenNewContact] = useState(false);
  const openNewContactModal = () => setOpenNewContact(true);
  const closeNewContact = () => setOpenNewContact(false);

  const [loading, setLoading] = useState(false);

  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = (value: boolean) => setOpenWarning(value);

  const [allowOpenNewModalContact, setAllowOpenNewModalContact] =
    useState(false);
  const [doNotDeleteContact, setDoNotDeleteContact] = useState('');

  const [sitesWithAll, setSitesWithAll] = useState<BasicArray[]>([]);
  const {
    linkForm,
    updateLinkForm,
    editLinkForm,
    linkFormErrors,
    validUrl,
    validateLinkFormField,
    updateValidUrl,
    setLinkFormErrors,
    contactsValues,
    replaceObjEditForm,
    addContactValues,
    pendingListObject,
    tabForm,
  } = useLinkForm();
  const {
    formSidebar,
    loadingForm,
    updateLoadingForm,
    updateSidebar,
    updateAvailable,
    available,
  } = useSidebar();
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;

  const [loadingUrl, setLoadingUrl] = useState(false);

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [linkStatus, setLinkStatus] = useState<LinkStatuses[]>([]);
  const [disabledStatus, setDisabledStatus] = useState(false);

  const [openModalEmail, setOpenModalEmail] = useState(false);
  const handleOpenModalEmail = (value: boolean) => setOpenModalEmail(value);

  const [emailSave, setEmailSave] = useState<{
    site: number;
    contacts: string[];
    contactsIds: number[];
    url: string;
    linkId: number;
  }>({ site: 0, contacts: [], url: '', linkId: 0, contactsIds: [] });

  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([
    { id: 0, label: 'Details', disabled: true },
    { id: 1, label: 'Contacts', disabled: true },
    { id: 2, label: 'Notes', disabled: true },
    { id: 3, label: 'History', disabled: true },
  ]);

  useEffect(() => {
    if (linkForm.id && linkForm.id !== 0) {
      setTabs([
        { id: 0, label: 'Details', disabled: false },
        { id: 1, label: 'Contacts', disabled: false },
        { id: 2, label: 'Notes', disabled: false },
        { id: 3, label: 'History', disabled: false },
      ]);
    } else {
      setTabs([
        { id: 0, label: 'Details', disabled: true },
        { id: 1, label: 'Contacts', disabled: true },
        { id: 2, label: 'Notes', disabled: true },
        { id: 3, label: 'History', disabled: true },
      ]);
    }
  }, [linkForm]);

  useEffect(() => {
    setTabValue(tabForm);
  }, [tabForm]);

  const validateField = <K extends keyof ObjectLinks>(
    value: ObjectLinks[K],
  ) => {
    setLinkFormErrors({
      site: value ? undefined : 'Site Selection is required',
    });
  };

  const handleInputChange = <K extends keyof ObjectLinks>(
    name: K,
    value: ObjectLinks[K],
  ) => {
    if (name === 'site')
      updateLinkForm(
        { [name]: value, audience: 0, affinity: 0, template: 0 },
        true,
      );
    else updateLinkForm({ [name]: value });

    validateLinkFormField(name);
  };
  const validateUrl = async (value: string, link_id: number) => {
    let errorUrl: string | undefined;
    if (value === '') {
      errorUrl = 'URL is required';
      updateValidUrl(false);
      setLinkFormErrors({ url: errorUrl });
      return;
    }
    try {
      setLoadingUrl(true);
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (resp.valid) {
        if (IS_USER_ADMIN.includes(UserContext.profile.role)) {
          errorUrl = resp.message;
        } else errorUrl = undefined;
        updateValidUrl(true);
      } else {
        errorUrl = resp.message;
        updateValidUrl(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUrl(false);
    }
  };

  const inputsLinkSection: FormInputsProps[] = [
    {
      id: 0,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL <span className="required-fields_dot">*</span>
          </InputLabel>
          <TextInputField
            onBlur={e => {
              let copyURL = e.target.value;
              if (e.target.value === '') return;

              if (!/(http(s?)):\/\//i.test(copyURL)) {
                copyURL = `https://${copyURL}`;
                handleInputChange('url', copyURL);
              }

              if (isURL(copyURL)) validateUrl(copyURL, linkForm.id ?? 0);
            }}
            value={linkForm.url}
            name="url"
            disabled={
              isLoadingContext.loading ||
              loadingUrl ||
              loadingForm ||
              available.disabled ||
              formSidebar.type === 'read'
            }
            error={!!linkFormErrors.url && !validUrl}
            onChange={e => handleInputChange('url', e.target.value)}
            size="small"
            placeholder="URL"
            id="url"
            helperText={linkFormErrors.url}
            fullWidth
            InputProps={
              loadingUrl
                ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        <CircularProgress sx={{ color: '#ABABAB' }} size={16} />
                      </InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          {validUrl === true && (
                            <CheckCircleIcon fontSize="inherit" />
                          )}
                          {validUrl === false && (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </>
                      </InputAdornment>
                    ),
                  }
            }
          />
        </>
      ),
    },
    {
      id: 1,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            value={
              sitesField.sitesOptions.find(
                item => item.id === linkForm.site,
              ) ?? { id: 0, name: '' }
            }
            onBlur={(value: string) => {
              if (
                sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField(1);
              if (
                value === '' ||
                !sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField(0);
            }}
            options={sitesWithAll}
            disabled={
              sitesField.loadingSites ||
              isLoadingContext.loading ||
              available.disabled ||
              formSidebar.type === 'read'
            }
            disabledId={undefined}
            placeholder="Sites"
            error={!!linkFormErrors.site}
            errorText={linkFormErrors.site}
            loading={sitesField.loadingSites}
            required
            onChange={(value: BasicArray | null) => {
              const idSite = value ? (value.id as number) : 0;

              handleInputChange('site', idSite);
            }}
          />
          {pendingListObject && pendingListObject.site.id !== linkForm.site && (
            <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
              Previous site: {pendingListObject?.site.name}
            </FormHelperText>
          )}
        </>
      ),
    },
    {
      id: 2,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <StatusSelectLinkFormField
            value={linkForm.status}
            loading={statusField.loadingStatusOptions}
            error={!!linkFormErrors.status}
            onChangeFunction={(value: string) => {
              if (value !== editLinkForm.status) {
                setNewStatus(value);
                setOpenStatusModal(true);
              } else handleInputChange('status', value);
            }}
            required
            nameInput="status"
            options={linkStatus}
            label="Status"
            disabled={
              statusField.loadingStatusOptions ||
              isLoadingContext.loading ||
              available.disabled ||
              disabledStatus ||
              formSidebar.type === 'read'
            }
          />
        </>
      ),
    },
    {
      id: 3,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <OwnersSelectLinkFormField
          required
          loading={ownersField.loadingOwners}
          error={!!linkFormErrors.owner}
          errorText={
            pendingListObject &&
            pendingListObject.id !== 0 &&
            pendingListObject.owner.id !== linkForm.owner
              ? `Previous owner: ${pendingListObject.owner.username}`
              : editLinkForm && editLinkForm.owner !== linkForm.owner
              ? `Previous owner: ${
                  ownersField.owners.find(
                    item => item.id === editLinkForm.owner,
                  )?.username
                }`
              : ''
          }
          value={linkForm.owner}
          onChangeFunction={(value: number) =>
            handleInputChange('owner', value)
          }
          options={ownersField.owners}
          nameInput="owner"
          label="Owner"
          disabled={
            ownersField.owners.length === 0 ||
            isLoadingContext.loading ||
            ownersField.loadingOwners ||
            available.disabled ||
            formSidebar.type === 'read'
          }
        />
      ),
    },
  ];

  const cleanAll = () => {
    setNotes([]);
    setHistory([]);

    setOpenNewContact(false);
    setLoading(false);
    setAllowOpenNewModalContact(false);
    setDoNotDeleteContact('');
    setSitesWithAll(sitesField.sitesOptions);
  };

  const saveLink = async (email?: boolean, copyLinkForm?: ObjectLinks) => {
    setLoading(true);
    const obj = copyLinkForm ? { ...copyLinkForm } : { ...linkForm };
    obj.send_email = email ?? false;

    try {
      let linkID: number = 0;

      if (obj.status === 'contact_not_found' && email) {
        obj.status = 'pending';
      }
      const response = await updateLink(obj, obj.id ?? 0);
      linkID = response.id;

      handleFeedbackMessage(`Link updated successfully`, 'success');
      if (email) {
        const listOfContacts = linkForm.contacts
          .filter(user => user.email !== '' && user.is_default && !user.is_form)
          .map(item => {
            return item.email;
          });
        const listOfContactsIds = linkForm.contacts
          .filter(user => user.email !== '' && user.is_default && !user.is_form)
          .map(item => {
            return item.id ?? 0;
          });
        setEmailSave({
          site: obj.site,
          contacts: listOfContacts,
          url: obj.url,
          linkId: obj.id ?? 0,
          contactsIds: listOfContactsIds,
        });
        handleOpenModalEmail(true);
      }
      setTabValue(0);
      replaceObjEditForm(
        ObjEditFormLink,
        LinkFormDefault,
        IS_USER_ADMIN.includes(UserContext.profile.role),
      );
      updateAvailable({ disabled: true, disabledSaveEmail: true });
      updateSidebar({ type: 'load' });
    } catch (err) {
      const error = err as any;
      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/${
        obj.id ?? 0
      }}`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error updating link`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const validateOnEdit = async (value: string, link_id: number) => {
    setLoadingUrl(true);
    let errorUrl: string | undefined;
    try {
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (!resp.valid) {
        errorUrl = resp.message;
        updateValidUrl(false);
      } else {
        updateValidUrl(true);
        if (IS_USER_ADMIN.includes(UserContext.profile.role))
          errorUrl = resp.message;
        else errorUrl = undefined;
      }
      setLinkFormErrors({ url: errorUrl });

      return resp.valid;
    } catch (err) {
      return true;
    } finally {
      setLoadingUrl(false);
    }
  };

  const validateEditLink = async () => {
    const {
      is_older_than_ninety_days,
      is_older_than_thirty_days,
      is_unsubscribed,
      unsubscribed_date,
      status,
      owner,
      url,
      id,
    } = editLinkForm;

    const objValid = {
      disabled: false,
      disabledSaveEmail: false,
    };
    const canEditLinks = [
      'administrator',
      'dev admin',
      'strategist',
      'manager',
      'account manager',
    ];

    const objNotValid = {
      disabled: true,
      disabledSaveEmail: true,
    };

    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return objValid;

    const RoleEdit = sendBackObject(UserContext.profile.role, ROLES_LINKS).edit;
    if (!RoleEdit) return objNotValid;

    const instantDisabled = ['project_closed', 'site_closed', 'global_closed'];

    if (
      instantDisabled.includes(status) &&
      !canEditLinks.includes(UserContext.profile.role)
    )
      return objNotValid;

    const dateNow = dayjs();
    if (is_unsubscribed && unsubscribed_date) {
      const dateUnsub = dayjs(unsubscribed_date);
      const is15DaysAfter = dateNow.isAfter(dateUnsub.add(15, 'day'));

      if (!is15DaysAfter) {
        objValid.disabledSaveEmail = true;
        handleFeedbackMessage(
          'Save+Email function currently disabled due to a recent Unsubscribe',
          'error',
        );
      }

      if (status === 'contact_not_found' && is15DaysAfter) return objValid;
    }

    if (status === 'contact_not_found' && !is_older_than_ninety_days)
      return objNotValid;

    if (
      status === 'hold' &&
      !is_older_than_ninety_days &&
      !canEditLinks.includes(UserContext.profile.role)
    )
      return objNotValid;

    if (
      status === 'invalid_contact' &&
      !is_older_than_thirty_days &&
      !canEditLinks.includes(UserContext.profile.role)
    )
      return objNotValid;

    if (
      status === 'invalid_contact' &&
      is_older_than_thirty_days &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setAllowOpenNewModalContact(true);
      return objNotValid;
    }

    if (!is_older_than_ninety_days) {
      if (
        owner !== UserContext.id &&
        !canEditLinks.includes(UserContext.profile.role)
      )
        return objNotValid;
    } else if (!IS_USER_ADMIN.includes(UserContext.profile.role)) {
      if (UserContext.id !== owner) {
        const resp = await validateOnEdit(url, id ?? 0);
        if (resp) {
          updateLinkForm({ owner: UserContext.id });
        } else return objNotValid;
      }
    }

    return objValid;
  };

  const handleSiteFromObj = () => {
    const copyArr: BasicArray[] = [...sitesField.sitesOptions];
    if (!sitesField.sitesOptions.find(item => item.id === linkForm.site)) {
      copyArr.push({
        id: ObjEditFormLink.site,
        name: ObjEditFormLink.site_name,
      });
    }
    setSitesWithAll(copyArr);
  };

  const validationOfForm = async () => {
    const objValid = await validateEditLink();
    handleSiteFromObj();
    updateAvailable(objValid);
    updateLoadingForm(false);
    if (
      objValid.disabled === false &&
      !linkFormErrors.url &&
      validUrl === false
    )
      updateValidUrl(true);
  };

  const getHistory = async () => {
    setLoadingHistory(true);

    try {
      const fetchHistory = await fetchLinkHistory(editLinkForm.id ?? 0);
      setHistory(fetchHistory);
    } catch (err) {
      setHistory([]);
    } finally {
      setLoadingHistory(false);
    }
  };

  const getNotes = async () => {
    setLoadingNotes(true);
    try {
      const fetchNotes = await fetchLinkNotes(editLinkForm.id ?? 0);
      setNotes(fetchNotes);
    } catch (err) {
      setNotes([]);
    } finally {
      setLoadingNotes(false);
    }
  };

  const getPromises = async () =>
    Promise.all([validationOfForm(), getNotes(), getHistory()]);

  useEffect(() => {
    if (editLinkForm.id && editLinkForm.id !== 0) {
      console.log(editLinkForm);
      cleanAll();
      setTimeout(() => {
        getPromises();
      }, 500);
    }
  }, [editLinkForm]);

  const isFormValid = (): boolean => {
    const linkFormFieldsValid = !(
      linkFormErrors.site ||
      linkFormErrors.status ||
      linkFormErrors.owner
    );

    const hasValidValues = !!(
      linkForm.site !== 0 &&
      linkForm.status &&
      linkForm.owner
    );

    const contactsLoading = contactsValues.some(contact => contact.loading);

    const contactsErrors = linkFormErrors.contacts.some(contactError =>
      Object.values(contactError).some(error => error),
    );

    const contactsValid = linkForm.contacts.every((contact, index) => {
      const isForm = contact.is_form;
      return (
        (isForm
          ? contact.name && contact.form_url && contact.form_text
          : contact.email && contactsValues[index].validEmail === true) &&
        contact.status
      );
    });

    return (
      linkFormFieldsValid &&
      hasValidValues &&
      validUrl &&
      !loadingUrl &&
      contactsValid &&
      !contactsErrors &&
      !contactsLoading
    );
  };

  // FUNCTIONALITY OF STATUS

  const getStatusFromLink = () => {
    if (linkForm.text_note) {
      const findIndex = linkForm.text_note.indexOf('Note: ');
      if (findIndex !== -1)
        return linkForm.text_note.slice(
          findIndex + 5,
          linkForm.text_note.length,
        );
      return '';
    }
    return '';
  };

  const updateStatus = (note: string) => {
    if (linkForm.text_note) {
      const linkClosedIndex = linkForm.text_note.indexOf('Link Close');
      const contactClosedIndex = linkForm.text_note.indexOf('Contact Close');
      const noteIndex = linkForm.text_note.indexOf('Note:');
      if (
        (linkClosedIndex !== -1 && contactClosedIndex !== 1) ||
        (linkClosedIndex === -1 && contactClosedIndex !== -1)
      ) {
        const newNote = `${linkForm.text_note.slice(
          contactClosedIndex,
          linkClosedIndex === -1 ? noteIndex : linkClosedIndex,
        )}\nNote: ${note}`;

        updateLinkForm({ text_note: newNote, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
      if (linkClosedIndex !== -1 && contactClosedIndex === -1) {
        const newNote = `Note: ${note}`;
        updateLinkForm({ text_note: newNote, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
      if (linkClosedIndex === -1 && contactClosedIndex === -1) {
        updateLinkForm({ text_note: note, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
    }
    updateLinkForm({ text_note: note, status: newStatus });
    setOpenStatusModal(false);
  };

  const avoidStatus = [
    'project_closed',
    'site_closed',
    'global_closed',
    'invalid_contact',
    'unsubscribed',
  ];
  const statusExcluded = {
    project_closed: 'Project Closed',
    site_closed: 'Site Closed',
    global_closed: 'Global Closed',
    invalid_contact: 'Invalid Contact',
    unsubscribed: 'Unsubscribed',
  };

  useEffect(() => {
    if (statusField.statusOptions.length > 0) {
      const removedStatus = statusField.statusOptions.filter(
        item => !avoidStatus.includes(item.value),
      );

      if (!removedStatus.find(item => item.value === linkForm.status)) {
        const key = linkForm.status as keyof typeof statusExcluded;
        removedStatus.push({
          group: 'Enabled',
          value: key,
          name: statusExcluded[key],
        });
      }
      setLinkStatus(removedStatus);
    }
  }, [linkForm.status, statusField.statusOptions]);

  const updateNewContactAndAllowEdit = (newContact: ContactsFormLink) => {
    updateLinkForm({
      status: 'pending',
      owner: UserContext.id,
      contacts: [...linkForm.contacts, newContact],
    });
    setLinkFormErrors({
      ...linkFormErrors,
      contacts: [...linkFormErrors.contacts, {}],
    });
    addContactValues({
      loading: false,
      validEmail: false,
      message: '',
      previousChanged: false,
      contact: null,
    });
    setDoNotDeleteContact(newContact.email);
    closeNewContact();
  };

  const updateNewContact = (newContact: ContactsFormLink) => {
    updateLinkForm({
      contacts: [...linkForm.contacts, newContact],
    });
    setLinkFormErrors({
      ...linkFormErrors,
      contacts: [...linkFormErrors.contacts, {}],
    });
    addContactValues({
      loading: false,
      validEmail: true,
      message: '',
      previousChanged: false,
      contact: null,
    });
  };

  const closeButtonContacts = () => {
    const indexesOfContacts = linkForm.contacts
      .map((item, index) => {
        return { ...item, id: index };
      })
      .filter(item => item.status === 'open');

    return indexesOfContacts.filter(item => item.email !== '' || item.is_form);
  };

  const updateContactsClosed = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';

      copyObject.close_reason = 'All Contacts Closed';
      updateLinkForm(copyObject);
    } else
      updateLinkForm({
        ...linkForm,
        contacts: copyContacts,
        text_note: newNote,
      });
  };

  const updateContactsClosedAndSave = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    setLoading(true);
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';
      copyObject.close_reason = 'All Contacts Closed';
    } else {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
    }

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    updateLinkForm(copyObject);

    saveLink(undefined, copyObject);
  };

  const updateLinkClosed = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    updateLinkForm({
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);
  };

  const updateLinkClosedAndSave = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    setLoading(true);

    const copyObj = {
      ...linkForm,
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    };

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    updateLinkForm({
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    saveLink(undefined, copyObj);
  };

  const handleEmailSent = () => {
    if (linkForm.contacts.filter(item => item.is_default).length === 0) {
      handleFeedbackMessage(
        'At least one contact included in template is required',
        'error',
      );

      return;
    }
    if (linkForm.contacts.filter(item => item.is_default).length > 1) {
      handleFeedbackMessage(
        'You can only have one contact included in template',
        'error',
      );

      return;
    }
    saveLink(true);
  };

  useEffect(() => {
    console.log(editLinkForm);
  }, [editLinkForm]);

  return (
    <>
      {(formSidebar.type === 'read' || formSidebar.type === 'load') && (
        <FlexBox justifyContent="end" mb={1.5}>
          <FormButtons
            loading={false}
            variant="contained"
            sx={{
              background: '#84D0F0',
              color: 'black',
              '&:hover': {
                backgroundColor: '#84D0F0',
              },
            }}
            disabled={
              available.disabled ||
              linkForm.id === 0 ||
              !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
              loading ||
              loadingForm
            }
            onClick={() => updateSidebar({ type: 'edit' })}
          >
            Edit Link
          </FormButtons>
        </FlexBox>
      )}
      {formSidebar.type === 'edit' && (
        <FlexBoxBetween marginBottom={1.5}>
          <>
            <FlexBox>
              <MainButton
                onClick={() => closeWarning(true)}
                disabled={loading}
                variant="contained"
                sx={{
                  marginRight: 1,

                  fontSize: 12,
                  backgroundColor: '#FFFFFF',
                  color: 'black',
                  border: '1px solid grey',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                  },
                }}
              >
                Cancel
              </MainButton>
              <StatusClosed
                updateNewContact={updateNewContact}
                disabled={available.disabled}
                allContacts={linkForm.contacts}
                contactsValidations={contactsValues}
                site={linkForm.site}
                contacts={closeButtonContacts()}
                updateContactsClosed={updateContactsClosed}
                updateLinkClosed={updateLinkClosed}
                updateLinkClosedAndSave={updateLinkClosedAndSave}
                updateContactsClosedAndSave={updateContactsClosedAndSave}
                disabledSave={
                  !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                  available.disabled ||
                  !isFormValid()
                }
              />
            </FlexBox>
            <FlexBox>
              <MainButton
                loading={loading}
                variant="contained"
                sx={{
                  marginX: 1,
                  background: '#BAF372',
                  fontSize: 12,
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#BAF372',
                  },
                }}
                disabled={
                  !isFormValid() ||
                  available.disabled ||
                  linkForm.id === 0 ||
                  !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                  loading
                }
                onClick={() => saveLink()}
              >
                Save
              </MainButton>
              <FormButtons
                variant="contained"
                disabled={
                  !isFormValid() ||
                  available.disabledSaveEmail ||
                  loading ||
                  linkForm.id === 0 ||
                  !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                  linkForm.contacts.filter(item => item.is_default).length ===
                    0 ||
                  linkForm.contacts.filter(
                    item => item.status !== 'invalid_contact',
                  ).length === 0
                }
                loading={false}
                sx={{
                  fontSize: 12,
                  backgroundColor: '#84D0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#84D0F0',
                  },
                }}
                onClick={() => handleEmailSent()}
              >
                Save + Email
              </FormButtons>
            </FlexBox>
          </>
        </FlexBoxBetween>
      )}
      <Divider />

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: 1,
        }}
      >
        <TabParent
          valueTab={tabValue}
          smaller
          setTab={setTabValue}
          tabs={tabs}
          center
        />
      </Box>
      <Box display={tabValue === 0 ? '' : 'none'}>
        <BoxAddSettings title="Link" pl={0} pr={0} mb={1} pt={1.5}>
          <div className="form-grids">
            {inputsLinkSection.map(input => (
              <div
                key={input.id}
                className={input.fullSpace ? 'whole-grid' : ''}
              >
                {input.body}
              </div>
            ))}
          </div>
          {(editLinkForm.added_date !== '' ||
            editLinkForm.modified_date !== '') && (
            <div className="form-grids" style={{ marginTop: '40px' }}>
              <Box>
                <InputLabel
                  htmlFor="created_date"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  Created Date
                </InputLabel>
                <DateInput
                  value={
                    pendingListObject
                      ? pendingListObject.created
                      : editLinkForm.added_date
                  }
                  fullWidth
                  disabled
                  handleChangeDate={(date: string) => {}}
                />
              </Box>

              <Box>
                <InputLabel
                  htmlFor="modified_date"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  Updated Date
                </InputLabel>
                <DateInput
                  value={
                    pendingListObject
                      ? pendingListObject.modified
                      : editLinkForm.modified_date
                  }
                  fullWidth
                  disabled
                  handleChangeDate={(date: string) => {}}
                />
              </Box>
            </div>
          )}
        </BoxAddSettings>
      </Box>
      <Box mt={2} display={tabValue === 1 ? '' : 'none'}>
        {linkForm.contacts.length > 0 && (
          <ContactsEdit
            handleFeedbackMessage={handleFeedbackMessage}
            allowOpenNewModalContact={allowOpenNewModalContact}
            openNewContactModal={openNewContactModal}
            doNotDeleteContact={doNotDeleteContact}
          />
        )}
      </Box>
      <Box display={tabValue === 2 ? '' : 'none'}>
        <LinkNotes
          handleFeedbackMessage={handleFeedbackMessage}
          loadingNotes={loadingNotes}
          notes={notes}
          getNotes={getNotes}
        />
      </Box>
      <Box display={tabValue === 3 ? '' : 'none'}>
        <LinkHistory loadingHistory={loadingHistory} history={history} />
      </Box>

      <StatusModal
        open={openStatusModal}
        closeModal={setOpenStatusModal}
        linkNote={getStatusFromLink()}
        updateStatus={updateStatus}
      />

      <AddNewContactModal
        contacts={linkForm.contacts.filter(item => item.email !== '')}
        open={openNewContact}
        updateNewContactAndAllowEdit={updateNewContactAndAllowEdit}
        closeModal={closeNewContact}
        site={linkForm.site}
      />

      <LinkEmailSave
        open={openModalEmail}
        closeModal={() => handleOpenModalEmail(false)}
        contacts={emailSave.contacts}
        site={emailSave.site}
        url={emailSave.url}
        linkId={emailSave.linkId}
        contactsIds={emailSave.contactsIds}
        handleFeedbackMessage={handleFeedbackMessage}
      />

      <AddWarningLink
        open={openWarning}
        closeModal={closeWarning}
        body="If you decide to continue you will lose your progress on this link"
        continueModal={() => {
          closeWarning(false);
          setTabValue(0);
          replaceObjEditForm(
            ObjEditFormLink,
            LinkFormDefault,
            IS_USER_ADMIN.includes(UserContext.profile.role),
          );
          updateAvailable({ disabled: true, disabledSaveEmail: true });
          updateSidebar({ type: 'read' });
        }}
      />
    </>
  );
};

export default MainForm;

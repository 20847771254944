import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useLinkForm from '../../../../stores/links/useLinkForm';
import { CustomTextAreaField } from '../../../../components/inputs';
import { MainButton } from '../../../../components/buttons';
import Spinner from '../../../../components/Loading/Spinner';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { NotesReq } from '../../../../models/Notes';
import { SeverityType } from '../../../../models/General';
import { addNoteLink, fetchLinkNotes } from '../../../../helpers/links';
import { UserContextType } from '../../../../models/User';
import Context from '../../../../context/UserContext';
import useSidebar from '../../../../stores/links/useSidebar';

interface LinkNotesProps {
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  getNotes: () => void;
  notes: NotesReq[];
  loadingNotes: boolean;
}

const LinkNotes: React.FC<LinkNotesProps> = ({
  handleFeedbackMessage,
  getNotes,
  notes,
  loadingNotes,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { linkForm } = useLinkForm();
  const { formSidebar } = useSidebar();
  const { UserContext } = useContext(Context) as UserContextType;

  const [pagination, setPagination] = useState(5);
  const results = notes.slice(0, pagination);

  const [loadingNewNote, setLoadingNewNote] = useState(false);
  const [note, setNote] = useState('');

  const addNote = async () => {
    setLoadingNewNote(true);

    const newNoteObject = {
      body: note,
      link: linkForm.id ?? 0,
      user: UserContext.id,
    };

    try {
      await addNoteLink(newNoteObject);
      setNote('');
      handleFeedbackMessage('Note created succesfully', 'success');
      getNotes();
    } catch (err) {
      handleFeedbackMessage('Error creating new note', 'error');
    } finally {
      setLoadingNewNote(false);
    }
  };

  useEffect(() => {
    if (linkForm.id && linkForm.id !== 0) {
      setPagination(5);
      setLoadingNewNote(false);
      setNote('');
    }
  }, [linkForm.id]);

  return (
    <>
      <Box mt={3}>
        <CustomTextAreaField
          minRows={6}
          multiline
          maxRows={Infinity}
          disabled={formSidebar.type === 'read'}
          required
          value={note}
          onChange={e => setNote(e.target.value)}
        />

        <Box justifyContent="end" display="flex">
          <MainButton
            variant="contained"
            loading={loadingNotes || loadingNewNote}
            sx={{ marginTop: 1 }}
            disabled={note === '' || formSidebar.type === 'read'}
            onClick={addNote}
          >
            Add Note
          </MainButton>
        </Box>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <Box pr={1}>
        {loadingNotes && <Spinner />}
        {!loadingNotes && notes.length > 0 && (
          <>
            {results.map(item => (
              <Box key={item.id} marginTop={3}>
                <FlexBoxBetween>
                  <FlexBox>
                    <div className="navbar-user" />
                    <Typography
                      sx={{
                        ml: 1.5,
                        fontWeight: 600,
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.8)',
                      }}
                    >
                      {item.user ? item.user.username : ''}
                    </Typography>
                  </FlexBox>
                  <Typography
                    fontWeight={500}
                    fontSize={13}
                    color="rgba(0, 0, 0, 0.8)"
                  >
                    {dayjs(item.created).format('MM/DD/YYYY')} -{' '}
                    {dayjs(item.created).tz('America/New_York').format('h:mma')}{' '}
                    EST
                  </Typography>
                </FlexBoxBetween>
                <Typography
                  marginTop={1.6}
                  fontSize={15}
                  marginLeft={0.8}
                  whiteSpace="pre-wrap"
                >
                  {item.body}
                </Typography>
              </Box>
            ))}
            {notes.length > pagination && (
              <Box display="flex" justifyContent="center" marginTop={1}>
                <IconButton
                  size="large"
                  sx={{ borderRadius: 20 }}
                  onClick={() => setPagination(pagination + 5)}
                >
                  <Typography>Load more notes...</Typography>
                </IconButton>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default LinkNotes;
